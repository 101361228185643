/* eslint react-hooks/rules-of-hooks: 'warn' */

import { FC, useEffect, useState } from 'react';
import { Checkbox } from '@croquiscom-pvt/zds';
import { vars } from '@croquiscom-pvt/zds/dist/tokens';
import styled from '@emotion/styled';

import { Gray_Light_500, Gray_Light_900, Typography } from '@common/styles';
import { TextAndSortingComponentType } from '@domains/storehome-common';

import { ComponentProps } from '../../types';
import { SortButton } from './sort-button';

interface Props extends ComponentProps<Omit<TextAndSortingComponentType, 'type'>> {
  active_navigation_id?: string;
  onChangeFilter: (params: {
    check_button_item_ids: string[];
    sorting_item_id: string;
    sub_filter_id_list: string[];
  }) => void;
}

export const Filter: FC<Props> = ({ component: sort_component, active_navigation_id, onChangeFilter }) => {
  if (!sort_component) {
    return null;
  }
  const {
    main_title,
    sub_title,
    check_button_item_list = [],
    sorting_item_list = [],
    zigzin_filter_item_list = [],
  } = sort_component;

  const [check_button_ids, setCheckButtonId] = useState<string[]>(() =>
    check_button_item_list
      .filter((check_button_item) => check_button_item.selected)
      .map((check_button_item) => check_button_item.id),
  );

  const [sub_filter_id_list, setSubFilterIdList] = useState<string[]>(() =>
    zigzin_filter_item_list
      .filter((check_button_item) => check_button_item.selected)
      .map((check_button_item) => check_button_item.id),
  );
  const [sorting_list, setSortingList] = useState(sorting_item_list);

  useEffect(() => initFilter(), [active_navigation_id]);

  const initFilter = () => {
    setCheckButtonId([]);
    setSubFilterIdList([]);
  };

  const changeCheckBoxItem = (id: string, checked: boolean) => {
    setCheckButtonId((prev) => {
      const new_check_button_ids = checked ? [...prev, id] : prev.filter((p) => p !== id);
      const sorting_item_id = sorting_list.filter((item) => item.selected)[0]?.id || '';
      onChangeFilter({
        check_button_item_ids: new_check_button_ids,
        sorting_item_id,
        sub_filter_id_list: [],
      });
      setSubFilterIdList([]);
      return new_check_button_ids;
    });
  };

  const changeSortItem = (id: string) => {
    setSortingList((prev) => {
      onChangeFilter({
        check_button_item_ids: check_button_ids,
        sorting_item_id: id,
        sub_filter_id_list,
      });

      return prev.map((data) => ({
        ...data,
        selected: data.id === id,
      }));
    });
  };

  const changeCheckZigzinFilter = (id: string, checked: boolean) => {
    setSubFilterIdList((prev) => {
      const new_sub_filter_ids = checked ? [...prev, id] : prev.filter((p) => p !== id);
      const sorting_item_id = sorting_list.filter((item) => item.selected)[0]?.id || '';
      onChangeFilter({
        check_button_item_ids: check_button_ids,
        sorting_item_id,
        sub_filter_id_list: new_sub_filter_ids,
      });
      return new_sub_filter_ids;
    });
  };

  return (
    <>
      <Wrap className='filter'>
        {main_title?.text && <MainTitle className={Typography.BODY_17_SEMIBOLD}>{main_title.text}</MainTitle>}
        <FilterWrap>
          {sub_title?.text && <SubTitle className={Typography.CAPTION_11_BOLD}>{sub_title.text}</SubTitle>}

          {check_button_item_list.map(({ id, name, disabled }) => (
            <Checkbox
              key={id}
              size='small'
              style={{ height: '100%' }}
              disabled={disabled || false}
              checked={check_button_ids.includes(id)}
              onChange={(checked) => changeCheckBoxItem(id, checked)}
            >
              <span>{name ?? ''}</span>
            </Checkbox>
          ))}

          <SortButtonWrap>
            {sorting_list.length > 0 && <SortButton sort_item_list={sorting_list} onChange={changeSortItem} />}
          </SortButtonWrap>
        </FilterWrap>
      </Wrap>

      {zigzin_filter_item_list.length > 0 && (
        <SubFilterContainer>
          {zigzin_filter_item_list.map(({ id, name, html_name, disabled }) => (
            <Checkbox
              key={id}
              size='small'
              style={{ height: '100%' }}
              disabled={disabled || false}
              checked={sub_filter_id_list.includes(id)}
              onChange={(checked) => changeCheckZigzinFilter(id, checked)}
            >
              <span dangerouslySetInnerHTML={{ __html: html_name?.normal ?? name ?? '' }} />
            </Checkbox>
          ))}
        </SubFilterContainer>
      )}
    </>
  );
};

const Wrap = styled.section`
  padding: 0 16px;
  position: relative;
`;

const MainTitle = styled.p`
  padding: 14px 0 0 0;
  height: 41px;
  letter-spacing: -0.3px;
  color: ${Gray_Light_900};
`;

const SubTitle = styled.span`
  color: ${Gray_Light_500};
`;

const FilterWrap = styled.div`
  display: flex;
  align-items: center;
  height: 48px;

  label + label {
    margin-left: 12px;
  }
`;

const SortButtonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const SubFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 48px;
  padding: 0 16px;
  der-top: 1px s d ${vars.color.dividerThin};
`;
